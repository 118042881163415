export const iconCustomers = [
  '128 128',
  `
    <title>users</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="users" transform="translate(1.000000, 1.000000)">
            <g id="Group" stroke="#99ACBC" stroke-width="1.5">
                <polygon id="Path" points="100.5705 29.0625 100.5965 87.1515 50.2335 116.1755 0.0085 87.1625 0.0085 29.0865 50.3045 0.0305"></polygon>
                <line x1="0" y1="87.1529" x2="50.297" y2="58.1139" id="Path"></line>
                <line x1="100.5884" y1="87.1426" x2="50.2964" y2="58.1136" id="Path"></line>
                <line x1="50.2967" y1="0" x2="50.2967" y2="58.114" id="Path"></line>
            </g>
            <path d="M71.7544,56.0744 C73.3924,56.9244 74.4384,58.9234 74.4384,61.7294 C74.4394,66.5404 74.4394,73.9624 74.4394,73.9794 L36.8082,95.6444 L36.8082,91.1484337 C36.8082,91.1484337 37.2239133,84.0675824 37.2259133,79.2475824 C37.2259133,75.2705824 37.658197,73.089988 40.624197,70.473988 C42.504197,68.819988 43.274,67.7508892 45.171,66.3848892 L47.4754,64.9004 L48.0024,65.1494 L55.6238,71.3022 C57.5218,70.7702 61.9200435,57.4643561 63.7870435,57.1463561 C65.1310435,56.9183561 67.7622024,56.0744 67.7622024,56.0744 L71.7544,56.0744 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M36.8082,95.6442 C36.8082,95.6442 36.8082,85.3632 36.8102,80.5442 C36.8102,76.5662 38.8442,72.0442 41.8092,69.4282 C43.6902,67.7752 45.5782,66.2652 47.4752,64.9002 L55.6242,71.3022 L63.7722,56.7512 C65.6692,56.2192 67.5582,55.8412 69.4382,55.6142 C72.4042,55.2642 74.4382,57.7522 74.4382,61.7292 C74.4402,66.5402 74.4402,73.9632 74.4402,73.9792 L36.8082,95.6442 Z" id="Path" stroke="#192A42" stroke-width="1.5"></path>
            <path d="M55.624,29.639 C61.393,26.754 66.077,30.435 66.077,37.852 C66.077,45.27 61.393,53.634 55.624,56.518 C49.855,59.403 45.171,55.723 45.171,48.305 C45.171,40.888 49.855,32.523 55.624,29.639" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M55.624,29.639 C61.393,26.754 66.077,30.435 66.077,37.852 C66.077,45.27 61.393,53.634 55.624,56.518 C49.855,59.403 45.171,55.723 45.171,48.305 C45.171,40.888 49.855,32.523 55.624,29.639 L55.624,29.639 Z" id="Path" stroke="#192A42" stroke-width="1.5"></path>
            <path d="M61.141,31.6642 C66.91,28.7792 71.594,32.4602 71.594,39.8772 C71.594,47.2952 66.91,55.6592 61.141,58.5432 C55.372,61.4282 50.688,57.7482 50.688,50.3302 C50.688,42.9132 55.372,34.5482 61.141,31.6642" id="Path" fill="#128AFB" class="svg-fill-primary" fill-rule="nonzero"></path>
        </g>
    </g>
  `,
]
