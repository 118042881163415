import React, { useState, useEffect, Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import { CContainer } from '@coreui/react-pro'
import './scss/style.scss'
import { getSites, getConfigurations } from './api/Emporix'
import _apps from './_appsManagement'
import { env, setCssVariable, TENANT_KEY } from './utils'
import Loader from './components/loader/Loader'
import i18n from './translations/i18n'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./public/login/Login'))

const App = () => {
  const query = new URLSearchParams(window.location.search)

  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(null)

  const setUILanguage = (_language) => {
    i18n.changeLanguage(_language)
  }

  const switchTenant = (tenants, tenant) => {
    let _tenant = null
    for (let i = 0; i < tenants.length; i += 1) {
      if (tenants[i].tenant === tenant) _tenant = tenants[i]
    }
    if (!_tenant) return authenticate(null)
    document.location = `${env.login_url}/switch-tenant?redirectUri=${env.auth_url}&clientId=${_tenant.clientId}&tenant=${_tenant.tenant}`
    localStorage.setItem(TENANT_KEY, tenant)
  }

  const loadTenant = (accessToken, tenants, tenant, site, currency, language, redirect) => {
    if (redirect === undefined) redirect = true
    // if (!accessToken) return authenticate(null)
    if (auth && auth.tenant && auth.tenant !== tenant) return switchTenant(tenants, tenant)

    let _token = { access_token: accessToken }
    _token.user = {
      firstName: query.get('first_name'),
      lastName: query.get('last_name'),
      userId: query.get('user_id'),
      backofficeUserNumber: query.get('backofficeUserNumber'),
    }
    _token.tenants = tenants
    _token.tenant = tenant
    localStorage.setItem(TENANT_KEY, tenant)

    // default
    _token.sites = [
      {
        code: 'default',
        name: 'Default',
      },
    ]
    _token.site = site ? site : ''
    localStorage.setItem('site', site)
    _token.currencies = [
      {
        id: 'eur',
        label: 'Euro',
      },
    ]
    _token.currency = currency ? currency : 'eur'
    _token.languages = [
      {
        id: 'en',
        label: 'English',
      },
    ]
    _token.language = language ? language : 'en'
    _token.theme = getTheme()

    // load latest
    updateAuth(redirect, _token, site, currency, language)
  }

  const updateAuth = (redirect, token, site, currency, language) => {
    let _token = token
    // get sites
    getSites(
      _token,
      (_sites) => {
        console.log('sites', _sites)
        if (_sites && _sites.length) {
          _token.sites = _sites
          if (!site) {
            let hasDefaultSite = false
            for (let j = 0; j < _sites.length; j += 1) {
              if (!_token.site && _sites[j].code && _sites[j].default) {
                _token.site = _sites[j].code
                localStorage.setItem('site', _sites[j].code)
                hasDefaultSite = true
                console.log('site', _token.site)
              }
            }
            // no default fallback
            if (!hasDefaultSite) {
              _token.site = _sites[0]?.code
              localStorage.setItem('site', _sites[0]?.code)
            }
          }
        }

        //get configurations
        getConfigurations(
          _token,
          (_configurations) => {
            console.log('configurations', _configurations)
            let _theme = null
            let __apps = _apps
            if (_configurations && _configurations.length) {
              for (let i = 0; i < _configurations.length; i += 1) {
                // currencies
                if (
                  _configurations[i].key &&
                  _configurations[i].key === 'project_curr' &&
                  _configurations[i].value
                ) {
                  const _currencies = JSON.parse(_configurations[i].value)
                  console.log('currencies', _currencies)
                  if (_currencies && _currencies.length) {
                    _token.currencies = _currencies
                    if (!currency) {
                      let hasDefaultCurrency = false
                      for (let j = 0; j < _currencies.length; j += 1) {
                        if (_currencies[j].id && _currencies[j].default) {
                          _token.currency = _currencies[j].id
                          hasDefaultCurrency = true
                          console.log('currency', _token.currency)
                        }
                      }
                      // no default fallback
                      if (!hasDefaultCurrency) _token.currency = _currencies[0]?.id
                    }
                  }
                }
                // languages
                if (
                  _configurations[i].key &&
                  _configurations[i].key === 'project_lang' &&
                  _configurations[i].value
                ) {
                  const _languages = JSON.parse(_configurations[i].value)
                  console.log('languages', _languages)
                  if (_languages && _languages.length) {
                    _token.languages = _languages
                    if (!language) {
                      let hasDefaultLanguage = false
                      for (let k = 0; k < _languages.length; k += 1) {
                        if (_languages[k].id && _languages[k].default) {
                          _token.language = _languages[k].id
                          hasDefaultLanguage = true
                          console.log('language', _token.language)
                        }
                      }
                      // no default fallback
                      if (!hasDefaultLanguage) _token.language = _languages[0]?.id
                    }
                  }
                }
                // theme
                if (
                  _configurations[i].key &&
                  _configurations[i].key === env.extensions.theme &&
                  _configurations[i].value
                ) {
                  _theme = _configurations[i].value // JSON.parse(_configurations[i].value)
                }
                // apps
                if (
                  _configurations[i].key &&
                  _configurations[i].key === env.extensions.apps &&
                  _configurations[i].value
                ) {
                  __apps = _configurations[i].value // JSON.parse(_configurations[i].value)
                }
              }
            }

            _token.theme = getTheme(_theme)
            _token.apps = __apps

            // set UI language
            setUILanguage(_token.language)

            // setLoading(false)
            authenticate(_token)
            if (redirect) document.location = '/'
          },
          (e) => {
            console.log('configurations error', e)
            // setLoading(false)
            authenticate(_token)
            if (redirect) document.location = '/'
          },
        )
      },
      (e) => {
        console.log('sites error', e)
        // setLoading(false)
        authenticate(_token)
        if (redirect) document.location = '/'
      },
    )
  }

  const getTheme = (_theme) => {
    // prettier-ignore
    let primary = _theme && _theme.colors && _theme.colors.primary ? _theme.colors.primary : "#128AFB";
    const theme = {
      colors: {
        primary,
        secondary:
          _theme && _theme.colors && _theme.colors.secondary ? _theme.colors.secondary : '#FFBD16',
        default:
          _theme && _theme.colors && _theme.colors.default ? _theme.colors.default : '#192940',
      },
      logos: {
        light:
          _theme && _theme.logos && _theme.logos.light
            ? _theme.logos.light
            : '/images/logo-light.svg',
        dark:
          _theme && _theme.logos && _theme.logos.dark ? _theme.logos.dark : '/images/logo-dark.svg',
      },
    }
    setCssVariable('--primary-color', primary)
    return theme
  }

  const authenticate = (_auth) => {
    console.log('storing auth', _auth)
    setLoading(true)
    setAuth(_auth)
    localStorage.setItem('auth', JSON.stringify(_auth))
    setLoading(false)
    if (_auth) return true
    return false
  }

  useEffect(() => {
    if (query.get('logout')) {
      // logout
      console.log('logout')
      authenticate(null)
      document.location = env.logout_url
    }

    if (
      // query.get('id_token') &&
      query.get('access_token') &&
      query.get('clients') &&
      query.get(TENANT_KEY)
    ) {
      // auto login
      authenticate(null)
      console.log('login', JSON.parse(query.get('clients')), query.get(TENANT_KEY))
      loadTenant(query.get('access_token'), JSON.parse(query.get('clients')), query.get(TENANT_KEY))
    } else {
      // get stored login
      let _auth = null
      try {
        _auth = localStorage.getItem('auth')
        if (_auth) _auth = JSON.parse(_auth)
      } catch (exception) {}
      console.log('auth', _auth)

      // check if logged in
      if (!_auth) {
        // no auth - back to login
        console.log('no auth')
        //setLoading(false)
        document.location = env.logout_url
      } else {
        // success getting auth stored - logged in
        console.log('updating auth')
        updateAuth(false, _auth, _auth.site, _auth.currency, _auth.language)
        //authenticate(_auth)
        setLoading(false)
      }
    }
  }, [])

  if (loading)
    return (
      <CContainer className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <Loader />
      </CContainer>
    )

  if (!auth)
    return (
      <HashRouter>
        <Suspense
          fallback={
            <CContainer className="vh-100 d-flex flex-column justify-content-center align-items-center">
              <Loader />
            </CContainer>
          }
        >
          <Switch>
            <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
          </Switch>
        </Suspense>
      </HashRouter>
    )

  return (
    <HashRouter>
      <Suspense
        fallback={
          <CContainer className="vh-100 d-flex flex-column justify-content-center align-items-center">
            <Loader />
          </CContainer>
        }
      >
        <Switch>
          <Route
            exact
            path="/logout"
            name="Logout"
            render={() =>
              authenticate(null) ? <Redirect to="/login" /> : <Redirect to="/login" />
            }
          />
          <Route
            path="/"
            ame="Home"
            render={(props) =>
              auth ? (
                <DefaultLayout
                  loadTenant={loadTenant}
                  authenticate={authenticate}
                  auth={auth}
                  {...props}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </Switch>
      </Suspense>
    </HashRouter>
  )
}
// <Route path="/" name="Home" render={(props) => <DefaultLayout auth={auth} authenticate={authenticate} {...props} />} />

export default App
