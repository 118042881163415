// import { env } from '../../utils'
// import { handleResolve, handleReject } from './Intercept'

/**
 * Get value in current language or fallback
 * @param {Object} value - Value
 * @return {String} Value in current language
 */
const getMultiLang = (auth, value) => {
  if (!value) return 'N/A'
  // non-object = non-multi-lang
  if (value && typeof value !== 'object') return value
  // current language
  if (auth && auth.language && value[auth.language]) return value[auth.language]
  // fallback to another language
  if (auth && auth.languages && auth.languages.length)
    for (let l = 0; l < auth.languages.length; l += 1) {
      if (value[auth.languages[l]]) return value[auth.languages[l]]
    }
  // fallback to first non-empty
  for (let e = 0; e < Object.entries(value).length; e += 1) {
    if (Object.entries(value)[e] && Object.entries(value)[e][1]) return Object.entries(value)[e][1]
  }
  return 'N/A'
}

/**
 * Get exchange rate between 2 currencies
 * @param {String} currency1 - Original currency
 * @param {String} currency2 - New currency
 * @return {Double} Exchange rate
 */
const getExchangeRate = (currency1, currency2) => {
  return 1
}

/**
 * Get formatted price
 * @param {Double} price - Original price
 */
const formatPrice = (value, precision) => {
  if (!value) return '0.00'
  if (!precision) return value.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return value.toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

/**
 * Get price in default currency
 * @param {Object} auth - Context
 * @param {Double} price - Original price
 * @param {String} currency - Original currency
 * @return {String} New price Default currency
 */
const priceCurrency = (auth, price, currency, precision) => {
  if (precision === undefined) precision = 2
  const newPrice = price * getExchangeRate(currency, auth.currency)
  return `${formatPrice(newPrice, precision)} ${auth.currency}`
}

export {
  // utils
  getMultiLang,
  getExchangeRate,
  formatPrice,
  priceCurrency,
}
