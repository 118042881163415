import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'

import { TRANSLATIONS_EN } from './en/translations'
import { TRANSLATIONS_DE } from './de/translations'

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  de: {
    translation: TRANSLATIONS_DE,
  },
}

i18n
  // .use(LanguageDetector) // auto-detect default language in client's browser (vs default tenant language)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // debug: false,
  })

export default i18n
