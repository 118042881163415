import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Orders
/**
 * Get orders
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getOrders = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      q: `siteCode:${auth.site}`,
      pageNumber: 1,
      pageSize: 100,
      sort: 'created:desc',
    }
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get order based on id
 * @param {String} id - Order id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getOrder = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get order status transitions
 * @param {String} id - Order id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getOrderStatus = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders/${id}/transitions`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get PDF order shipping note
 * @param {String} id - Order id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getPdfShippingNote = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/pdfmashup/shippingNote`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    method: 'POST',
    body: JSON.stringify({ orderId: id }),
  })
    .then((res) => res.blob())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add order
 * @param {Object} order - Order object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addOrder = (order, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(order),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update order based on id
 * @param {String} id - Order id
 * @param {Object} order - Order object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateOrder = (id, order, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PATCH',
    body: JSON.stringify(order),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update order status based on id
 * @param {String} id - Order id
 * @param {String} status - Order status (IN_CHECKOUT, CREATED, CONFIRMED, DECLINED, SHIPPED, COMPLETED)
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateOrderStatus = (id, status, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders/${id}/transitions`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify({ status: status }),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Order calculations
 * @param {String} id - Order id
 * @param {Array} entries - Entries qty
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateOrderCalculations = (id, entries, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders/${id}/calculations`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify({ entries }),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete order based on id
 * @param {String} id - Order id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteOrder = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/order-v2/${auth.tenant}/salesorders/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get PDF for order ids pack lists
 * @param {String} ids - Order ids
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getPdfPackLists = (ids, auth, resolve, reject) => {
  const options = {
    orderIds: ids.join(','),
    startNumber: 0,
    totalOrders: ids.length,
  }
  fetch(`${env.api_url}/pdfmashup/packlists?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
      Accept: 'application/pdf',
    },
    method: 'GET',
  })
    .then((res) => res.blob())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Create order invoice
 * @param {String} id - Order id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const createInvoice = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/pdfmashup/invoice`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'POST',
    body: JSON.stringify({ orderId: id }),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // orders
  getOrders,
  getOrder,
  getOrderStatus,
  updateOrderCalculations,
  getPdfShippingNote,
  addOrder,
  updateOrder,
  updateOrderStatus,
  deleteOrder,
  getPdfPackLists,
  createInvoice,
}
