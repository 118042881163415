// orders
import { iconOrders } from './assets/icons/orders'
import { iconPicklists } from './assets/icons/picklists'
import { iconSepa } from './assets/icons/sepa'
// categories
import { iconCatalogues } from './assets/icons/catalogues'
import { iconCategories } from './assets/icons/categories'
// products
import { iconProducts } from './assets/icons/products'
// import { iconSkus } from './assets/icons/skus'
import { iconLabels } from './assets/icons/labels'
import { iconBrands } from './assets/icons/brands'
import { iconSuppliers } from './assets/icons/suppliers'
import { iconLocations } from './assets/icons/locations'
// marketing
import { iconCustomers } from './assets/icons/customers'
//import { iconGroups } from './assets/icons/groups'
// import { iconPricelists } from './assets/icons/pricelists'
import { iconCoupons } from './assets/icons/coupons'
// reports
// import { iconLive } from './assets/icons/live'
import { iconReports } from './assets/icons/reports'
// settings
import { iconZones } from './assets/icons/zones'
import { iconAreas } from './assets/icons/areas'
import { iconTimes } from './assets/icons/times'
import { iconMethods } from './assets/icons/methods'
import { iconGroups } from './assets/icons/groups'
import { iconFees } from './assets/icons/fees'
import { iconTaxes } from './assets/icons/taxes'
import { iconUnits } from './assets/icons/units'
import { iconCountries } from './assets/icons/countries'
import { iconSites } from './assets/icons/sites'
import { iconCurrencies } from './assets/icons/currencies'
import { iconLanguages } from './assets/icons/languages'

const _apps = [
  {
    name: { en: 'Orders', de: 'Bestellungen' },
    icon: 'cisArtTrack',
    apps: [
      {
        name: { en: 'Orders', de: 'Bestellungen' },
        to: '/apps/management/orders',
        description: {
          en: 'Manage orders and order line items',
          de: 'Bestellungen verwalten und Einzelposten bestellen',
        },
        icon: iconOrders,
      },
      {
        name: { en: 'Picklists', de: 'Auswahlliste' },
        to: '/apps/management/picklists',
        description: {
          en: 'Help warehouse pickers to fulfill a customer order',
          de: 'Helfen Sie Lagerkommissionierern, einen Kundenauftrag zu erfüllen',
        },
        icon: iconPicklists,
      },
      {
        name: { en: 'SEPA', de: 'SEPA' },
        to: '/apps/management/sepa',
        description: {
          en: 'SEPA export for direct debit collections',
          de: 'SEPA-Export für Lastschrifteinzüge',
        },
        icon: iconSepa,
      },
    ],
  },

  {
    name: { en: 'Catalogues', de: 'Kataloge' },
    icon: 'cisLibraryBookmark',
    apps: [
      {
        name: { en: 'Catalogues', de: 'Kataloge' },
        to: '/apps/management/catalogues',
        description: { en: 'Manage catalogues', de: 'Kataloge verwalten' },
        icon: iconCatalogues,
      },
      {
        name: { en: 'Categories', de: 'Kategorien' },
        to: '/apps/management/categories',
        description: { en: 'Manage categories', de: 'Kategorien verwalten' },
        icon: iconCategories,
      },
    ],
  },

  {
    name: { en: 'Products', de: 'Produkte' },
    icon: 'cisListRich',
    apps: [
      {
        name: { en: 'Products', de: 'Produkte' },
        to: '/apps/management/products',
        description: { en: 'Manage products', de: 'Produkte verwalten' },
        icon: iconProducts,
      },
      /*
      {
        name: { en: 'SKUs', de: 'SKUs' },
        to: '/apps/management/locations',
        description: { en: 'Manage SKUs', de: 'SKUs verwalten' },
        icon: iconSkus,
      },
      */
      {
        name: { en: 'Labels', de: 'Etiketten' },
        to: '/apps/management/labels',
        description: { en: 'Manage labels', de: 'Etiketten verwalten' },
        icon: iconLabels,
      },
      {
        name: { en: 'Brands', de: 'Marken' },
        to: '/apps/management/brands',
        description: { en: 'Manage brands', de: 'Marken verwalten' },
        icon: iconBrands,
      },
      {
        name: { en: 'Suppliers', de: 'Lieferanten' },
        to: '/apps/management/suppliers',
        description: { en: 'Manage suppliers', de: 'Lieferanten verwalten' },
        icon: iconSuppliers,
      },
      {
        name: { en: 'Locations', de: 'Standorte' },
        to: '/apps/management/locations',
        description: { en: 'Manage locations and racks', de: 'Standorte verwalten' },
        icon: iconLocations,
      },
    ],
  },

  {
    name: { en: 'Marketing', de: 'Marketing' },
    icon: 'cisCenterFocus',
    apps: [
      {
        name: { en: 'Customers', de: 'Kunden' },
        to: '/apps/management/customers',
        description: { en: 'Manage customers', de: 'Kunden verwalten' },
        icon: iconCustomers,
      },
      {
        name: { en: 'Coupons', de: 'Gutscheine' },
        to: '/apps/management/coupons',
        description: { en: 'Manage coupons', de: 'Gutscheine verwalten' },
        icon: iconCoupons,
      },
    ],
  },

  {
    name: { en: 'Reports', de: 'Berichte' },
    icon: 'cisChartPie',
    apps: [
      {
        name: { en: 'Coupon Reports', de: 'Gutscheinberichte' },
        to: '/apps/management/reports-coupons',
        description: { en: 'Custom coupon reports', de: 'Benutzerdefinierte Gutscheinberichte' },
        icon: iconReports,
      },
      {
        name: { en: 'Revenue Reports', de: 'Einnahmenberichte' },
        to: '/apps/management/reports-revenue',
        description: { en: 'Custom revenue reports', de: 'Benutzerdefinierte Einnahmenberichte' },
        icon: iconReports,
      },
    ],
  },

  {
    name: { en: 'Settings', de: 'Einstellungen' },
    icon: 'cisSettings',
    apps: [
      {
        name: { en: 'Delivery Zones', de: 'Lieferzonen' },
        to: '/apps/management/zones',
        description: { en: 'Manage delivery zones', de: 'Lieferzonen verwalten' },
        icon: iconZones,
      },
      {
        name: { en: 'Delivery Methods', de: 'Versandmethoden' },
        to: '/apps/management/methods',
        description: { en: 'Delivery Methods', de: 'Versandmethoden verwalten' },
        icon: iconMethods,
      },
      {
        name: { en: 'Delivery Groups', de: 'Bereitstellungsgruppen' },
        to: '/apps/management/groups',
        description: { en: 'Manage delivery groups', de: 'Bereitstellungsgruppen verwalten' },
        icon: iconGroups,
      },
      {
        name: { en: 'Delivery Areas', de: 'Liefergebiete' },
        to: '/apps/management/areas',
        description: { en: 'Manage delivery areas', de: 'Liefergebiete verwalten' },
        icon: iconAreas,
      },
      {
        name: { en: 'Delivery Times', de: 'Lieferzeiten' },
        to: '/apps/management/times',
        description: { en: 'Manage delivery times', de: 'Lieferzeiten verwalten' },
        icon: iconTimes,
      },
      {
        name: { en: 'Additional Fees', de: 'Zusätzliche Gebühren' },
        to: '/apps/management/fees',
        description: { en: 'Manage additional fees', de: 'Zusätzliche Gebühren verwalten' },
        icon: iconFees,
      },
      {
        name: { en: 'Units', de: 'Einheiten' },
        to: '/apps/management/units',
        description: { en: 'Manage measurement units', de: 'Einheiten verwalten' },
        icon: iconUnits,
      },
      {
        name: { en: 'Taxes', de: 'Steuern' },
        to: '/apps/management/taxes',
        description: { en: 'Manage tax rates', de: 'Steuern verwalten' },
        icon: iconTaxes,
      },
      {
        name: { en: 'Sites', de: 'Sites' },
        to: '/apps/management/sites',
        description: { en: 'Manage sites', de: 'Sites verwalten' },
        icon: iconSites,
      },
      {
        name: { en: 'Countries', de: 'Länder' },
        to: '/apps/management/countries',
        description: { en: 'Manage countries', de: 'Länder verwalten' },
        icon: iconCountries,
      },
      {
        name: { en: 'Currencies', de: 'Währungen' },
        to: '/apps/management/currencies',
        description: { en: 'Manage currencies', de: 'Währungen verwalten' },
        icon: iconCurrencies,
      },
      {
        name: { en: 'Languages', de: 'Sprachen' },
        to: '/apps/management/languages',
        description: { en: 'Manage languages', de: 'Sprachen verwalten' },
        icon: iconLanguages,
      },
    ],
  },
]

export default _apps
