import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Brands
/**
 * Get brands
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getBrands = (auth, resolve, reject) => {
  fetch(`${env.api_url}/brand/brands`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get brand based on id
 * @param {String} id - Brand id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getBrand = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/brand/brands/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add brand
 * @param {Object} brand - Brand object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addBrand = (brand, auth, resolve, reject) => {
  fetch(`${env.api_url}/brand/brands`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(brand),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add brand media (image)
 * @param {Object} file - File
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addBrandMedia = (file, auth, resolve, reject) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('metadata', JSON.stringify({ domain: 'brand' }))
  fetch(`${env.api_url}/brand/media`, {
    headers: {
      // 'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: formData,
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update brand based on id
 * @param {String} id - Brand id
 * @param {Object} brand - Brand object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateBrand = (id, brand, auth, resolve, reject) => {
  fetch(`${env.api_url}/brand/brands/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(brand),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete brand based on id
 * @param {String} id - Brand id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteBrand = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/brand/brands/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // brands
  getBrands,
  getBrand,
  addBrand,
  addBrandMedia,
  updateBrand,
  deleteBrand,
}
