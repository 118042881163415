// prettier-ignore
export const TRANSLATIONS_EN = {
  "footer": {
    "rights": "All rights reserved."
  },
  "genera": {
    "settings": "General Settings"
  },
  "global": {
    "dashboard": "Dashboard",
    "orders": "Orders",
    "save": "Save",
    "saving": "Saving...",
    "settings": "Settings",
    "logout": "Logout",
    "cancel": "Cancel",
    "delete": "Delete"
  },
  "dashboard": {
    "title": "Edit the look and feel of your dashboard",
    "subtitle": "Add your logo, brand colors and images",
    "customizeTheme": "CUSTOMIZE THEME",
    "totalSales": "Total Sales",
    "sales": "Sales",
    "salesPerformance": "Sales Performance",
    "lastDay": "Last Day",
    "lastWeek": "Last Week",
    "lastMonth": "Last Month",
    "lastQuarter": "Last Quarter",
    "lastYear": "Last Year",
    "ordersBreakdown": "Orders Breakdown",
    "lastOrders": "Last Orders",
    "fulfilledOrders": "Fulfilled Orders",
    "widget": {
      "extension": "Widget Extensibility",
      "visibility": "Widget Visibility",
      "show": "Show Widget",
      "columns": "Widget Columns",
      "limit": "Widget Limit"
    }
  },
  "pendingOrders": "Pending Orders",
  "totalOrders": "Total Orders",
  "order": "Order",
  "orderDate": "Order Date",
  "customer": "Customer",
  "company": "Company",
  "orderAmount": "Order Amount",
  "deliveryDate": "Delivery Date",
  "deliveryTime": "Delivery Time",
  "status": "Status",
  "itemsPerPage": "Items per page",
  "manage": "Management",
  "tenant": "Tenant",
  "site": "Site",
  "currency": "Currency",
  "label": "Label",
  "manageSites": "Manage Sites",
  "manageLanguages": "Manage Languages",
  "manageCurrencies": "Manage Currencies",
  "commerceStudio": "Commerce Studio",
  "commerceExec": "Commerce Execution",
  "companySettings": "Company Settings",
  "accountSettings": "Account Settings",
  "management": {
    "commerceManagement": "Commerce Management",
    "compose": "Compose your digital commerce solution",
    "add": "Add or remove commerce management apps (packaged business capabilities)",
    "apps": "MANAGE APPS"
  },
  "settings": {
    "appSettings": "Apps Settings",
    "manageApps": "Manage commerce apps part of your composable commerce",
    "theme": "Theme Settings",
    "personalize": "Personalize the look and feel of your management dashboard",
    "changePass": "Change Password",
    "changeYourPass": "Change your password",
    "logout": "Logout of the management dashboard"
  },
  "pdfBtn": "PDF Button",
  "viewBtn": "View Button",
  "addUnit": "Add Unit",
  "editUnit": "Edit Unit",
  "unitsManagement": "Units Management",
  "addCoupon": "Add Coupon",
  "editCoupon": "Edit Coupon",
  "couponsManagement": "Coupons Management",
  "editOrder": "Edit Order",
  "ordersManagement": "Orders Management",
  "picklistsManagement": "Picklists Management",
  "sepaExp": "SEPA Export",
  "sepaExpManagement": "SEPA Exports Management",
  "editCustomer": "Edit Customer",
  "customersManegement": "Customers Management",
  "addSupplier": "Add Supplier",
  "editSupplier": "Edit Supplier",
  "suppliersManagement": "Suppliers Management",
  "addLabel": "Add Label",
  "editLabel": "Edit Label",
  "labelsManagement": "Labels Management",
  "addBrand": "Add Brand",
  "editBrand": "Edit Brand",
  "brandsManagement": "Brands Management",
  "addSite": "Add Site",
  "editSite": "Edit Site",
  "sitesManagement": "Sites Management",
  "addCountry": "Add Country",
  "editCountry": "Edit Country",
  "countriesManagement": "Countries Management",
  "addLang": "Add Language",
  "editLang": "Edit Language",
  "langManagement": "Languages Management",
  "addCurrency": "Add Currency",
  "editCurrency": "Edit Currency",
  "currencyManagement": "Currencies Management",
  "addLocation": "Add Location",
  "editLocation": "Edit Location",
  "locationsManagement": "Locations Management",
  "addFee": "Add Fee",
  "editFee": "Edit Fee",
  "feesManagement": "Delivery Fees Management",
  "addZone": "Add Delivery Zone",
  "editZone": "Edit Delivery Zone",
  "zonesManagement": "Delivery Zones Management",
  "addDeliv": "Add Delivery Method",
  "editDeliv": "Edit Delivery Method",
  "delivManagement": "Delivery Methods Management",
  "addDelivGrp": "Add Delivery Group",
  "editDelivGrp": "Edit Delivery Group",
  "delivGrpManagement": "Delivery Groups Management",
  "addTax": "Add Tax",
  "editTax": "Edit Tax",
  "taxesManagement": "Taxes Management",
  "addDelivArea": "Add Delivery Area",
  "editDelivArea": "Edit Delivery Area",
  "delivAreaManagement": "Delivery Areas Management",
  "addDelivTime": "Add Delivery Time",
  "editDelivTime": "Edit Delivery Time",
  "delivTimeManagement": "Delivery Times Management",
  "addCatalogue": "Add Catalogue",
  "editCatalogue": "Edit Catalogue",
  "cataloguesManagement": "Catalogues Management",
  "addCat": "Add Category",
  "editCat": "Edit Category",
  "catManagement": "Categories Management",
  "addProd": "Add Product",
  "editProd": "Edit Product",
  "prodManagement": "Products Management",
  "couponReports": "Coupon Reports",
  "revenueReports": "Revenue Reports",
  "extension": "Extension",
  "addApp": "Add App",
  "addGrp": "Add Group",
  "editApp": "Edit App",
  "addAppsGrp": "Add Apps Group",
  "editAppsGrp": "Edit Apps Group",
  "newSepa": "New SEPA Export",
  "new": "NEW",
  "extens": "Extensibility",
  "columns": "Columns",
  "limit": "Limit",
  "printList": "Print Pack Lists",
  "code": "Code",
  "name": "Name",
  "art": "Art",
  "select": "SELECT",
  "view": "VIEW",
  "edit": "EDIT",
  "deleteUp": "DELETE",
  "noOrders": "You do not have any orders yet",
  "noPicklists": "You do not have any picklists defined yet",
  "noSepa": "You do not have any SEPA exports",
  "startSepaExp": "START A SEPA EXPORT",
  "noCatalogues": "You do not have any catalogues defined yet",
  "addFirstCatalogue": "ADD YOUR FIRST CATALOGUE",
  "noCat": "You do not have any categories defined yet",
  "addFirstCat": "ADD YOUR FIRST CATEGORY",
  "allCatalogues": "All Catalogues",
  "noProd": "You do not have any products defined yet",
  "addFirstProd": "ADD YOUR FIRST PRODUCT",
  "noLabels": "You do not have any labels defined yet",
  "addFirstLabel": "ADD YOUR FIRST LABEL",
  "noBrands": "You do not have any brands defined yet",
  "addFirstBrand": "ADD YOUR FIRST BRAND",
  "noSuppliers": "You do not have any suppliers defined yet",
  "addFirstSupplier": "ADD YOUR FIRST SUPPLIER",
  "noLocations": "You do not have any locations defined yet",
  "addFirstLocation": "ADD YOUR FIRST LOCATION",
  "noCustomers": "You do not have any customers yet",
  "noCoupons": "You do not have any coupons defined yet",
  "addFirstCoupon": "ADD YOUR FIRST COUPON",
  "customerRev": "Customer Revenues",
  "supplierRev": "Supplier Revenues",
  "articleRev": "Article Revenues",
  "customerRevBy": "Customer Revenues by ",
  "Month": "Monat",
  "Week": "Woche",
  "From": "Von",
  "to": "zu",
  "Generate": "Generieren",
  "generating": "Generating...",
  "noDelivZone": "You do not have any delivery zones defined yet",
  "addFirstDelivZone": "ADD YOUR FIRST DELIVERY ZONE",
  "addZoneFirst": "You need to first add a delivery zone, in order to define delivery methods",
  "noDelivMethod": "You do not have any delivery methods defined yet",
  "addFirstDelivMethod": "ADD YOUR FIRST DELIVERY METHOD",
  "noDelivGroups": "You do not have any groups defined yet",
  "addFirstDelivGroup": "ADD YOUR FIRST DELIVERY GROUP",
  "noDelivArea": "You do not have any delivery areas defined yet",
  "addFirstDelivArea": "ADD YOUR FIRST DELIVERY AREA",
  "noDelivTime": "You do not have any delivery times defined yet",
  "addFirstDelivTime": "ADD YOUR FIRST DELIVERY TIME",
  "noFees": "You do not have any fees defined yet",
  "addFirstFee": "ADD YOUR FIRST FEE",
  "noUnits": "You do not have any units defined yet",
  "addFirstUnit": "ADD YOUR FIRST UNIT",
  "noTaxes": "You do not have any taxes defined yet",
  "addFirstTax": "ADD YOUR FIRST TAX",
  "noSites": "You do not have any sites defined yet",
  "addFirstSite": "ADD YOUR FIRST SITE",
  "noCountries": "You do not have any countries defined yet",
  "addFirstCountry": "ADD YOUR FIRST COUNTRY",
  "noCurrencies": "You do not have any currencies defined yet",
  "addFirstCurrency": "ADD YOUR FIRST CURRENCY",
  "noLang": "You do not have any languages defined yet",
  "addFirstLang": "ADD YOUR FIRST LANGUAGE",
  "colorTheme": "Color Scheme",
  "primaryColor": "Primary Color",
  "secondaryColor": "Secondary Color",
  "defaultColor": "Default Color",
  "logoLight": "Logo Light",
  "logoDark": "Logo Dark",
  "saveTheme": "Save Theme Settings",
  "reset": "Reset",
  "resetAll": "Reset All",
  "ok": "Ok",
  "confirm": "Confirm",
  "resetDefaultConf": "Are you sure you want to reset to default?",
  "catalogue": "Catalogue",
  "category": "Category",
  "searchProd": "Search Products",
  "searchByArt": "Search by article code",
  "Always": "Always",
  "categories": {
    "list": {
      "columns": {
        "code": "Code",
        "name": "Category Name",
        "published": "Published",
        "select": "Select",
        "visibilityStart": "Visibility Start",
        "visibilityEnd": "Visibility End"
      }
    }
  },
  "catalogs": {
    "list": {
      "columns": {
        "name": "Name",
        "publishedSites": "Published to",
        "status": "Status",
        "visibilityStart": "Visibility Start",
        "visibilityEnd": "Visibility End"
      }
    }
  }
};
