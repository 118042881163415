import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Fees
/**
 * Get fees
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getFees = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      siteCode: auth.site,
      pageNumber: 1,
      pageSize: 1000,
      sort: 'name:asc',
    }
  fetch(`${env.api_url}/fee/${auth.tenant}/fees?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get fee based on id
 * @param {String} id - Fee id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getFee = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/fee/${auth.tenant}/fees/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add fee
 * @param {Object} fee - Fee object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addFee = (fee, auth, resolve, reject) => {
  fetch(`${env.api_url}/fee/${auth.tenant}/fees`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(fee),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update fee based on id
 * @param {String} id - Fee id
 * @param {Object} fee - Fee object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateFee = (id, fee, auth, resolve, reject) => {
  fetch(`${env.api_url}/fee/${auth.tenant}/fees/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(fee),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete fee based on id
 * @param {String} id - Fee id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteFee = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/fee/${auth.tenant}/fees/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // fees
  getFees,
  getFee,
  addFee,
  updateFee,
  deleteFee,
}
