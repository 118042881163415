import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Labels
/**
 * Get labels
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getLabels = (auth, resolve, reject) => {
  fetch(`${env.api_url}/label/labels`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get label based on id
 * @param {String} id - Label id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getLabel = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/label/labels/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add label
 * @param {Object} label - Label object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addLabel = (label, auth, resolve, reject) => {
  fetch(`${env.api_url}/label/labels`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(label),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add label media (image)
 * @param {Object} file - File
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addLabelMedia = (file, auth, resolve, reject) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('metadata', JSON.stringify({ domain: 'label' }))
  fetch(`${env.api_url}/label/media`, {
    headers: {
      // 'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: formData,
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update label based on id
 * @param {String} id - Label id
 * @param {Object} label - Label object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateLabel = (id, label, auth, resolve, reject) => {
  fetch(`${env.api_url}/label/labels/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(label),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete label based on id
 * @param {String} id - Label id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteLabel = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/label/labels/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // labels
  getLabels,
  getLabel,
  addLabel,
  addLabelMedia,
  updateLabel,
  deleteLabel,
}
