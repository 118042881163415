import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Zones
/**
 * Get zones
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getZones = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      // activeMethods: false,
      expand: 'methods', // methods,fees
      // siteCode: auth.site,
      // pageNumber: 1,
      // pageSize: 1000,
      // sort: 'name:asc',
    }
  fetch(
    `${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones?${new URLSearchParams(options)}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': auth.language,
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get zone based on id
 * @param {String} id - Zone id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getZone = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add zone
 * @param {Object} zone - Zone object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addZone = (zone, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      //'Content-Language': '*',
      //'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(zone),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update zone based on id
 * @param {String} id - Zone id
 * @param {Object} zone - Zone object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateZone = (id, zone, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(zone),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete zone based on id
 * @param {String} id - Zone id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteZone = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

// Methods
/**
 * Get methods
 * @param {String} id - Zone id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getMethods = (zoneId, auth, resolve, reject, options) => {
  if (!options)
    options = {
      // activeMethods: false,
      expand: 'fees', // fees
      // siteCode: auth.site,
      // pageNumber: 1,
      // pageSize: 1000,
      // sort: 'name:asc',
    }
  fetch(
    `${env.api_url}/shipping/${auth.tenant}/${
      auth.site
    }/zones/${zoneId}/methods?${new URLSearchParams(options)}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': auth.language,
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get method based on id
 * @param {String} id - Zone id
 * @param {String} id - Method id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getMethod = (zoneId, id, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${zoneId}/methods/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add method
 * @param {String} id - Zone id
 * @param {Object} method - Method object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addMethod = (zoneId, method, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${zoneId}/methods`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(method),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update method based on id
 * @param {String} id - Zone id
 * @param {String} id - Method id
 * @param {Object} method - Method object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateMethod = (zoneId, id, method, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${zoneId}/methods/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(method),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete method based on id
 * @param {String} id - Zone id
 * @param {String} id - Method id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteMethod = (zoneId, id, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/zones/${zoneId}/methods/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

// Groups
/**
 * Get groups
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getGroups = (auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/groups`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': auth.language,
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get group based on id
 * @param {String} id - Group id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getGroup = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/groups/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add group
 * @param {Object} group - Group object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addGroup = (group, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/groups`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      //'Content-Language': '*',
      //'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(group),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update group based on id
 * @param {String} id - Group id
 * @param {Object} group - Group object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateGroup = (id, group, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/groups/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(group),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete group based on id
 * @param {String} id - Group id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteGroup = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/shipping/${auth.tenant}/${auth.site}/groups/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // zones
  getZones,
  getZone,
  addZone,
  updateZone,
  deleteZone,
  // methods
  getMethods,
  getMethod,
  addMethod,
  updateMethod,
  deleteMethod,
  // groups
  getGroups,
  getGroup,
  addGroup,
  updateGroup,
  deleteGroup,
}
