// prettier-ignore
export const TRANSLATIONS_DE = {
  "footer": {
    "rights": "Alle Rechte vorbehalten."
  },
  "global": {
    "dashboard": "Startseite",
    "orders": "Bestellungen",
    "save": "Speichern",
    "saving": "Sparen...",
    "settings": "Einstellungen",
    "logout": "Ausloggen",
    "cancel": "Stornieren",
    "delete": "Löschen"
  },
  "dashboard": {
    "title": "Bearbeiten Sie das Erscheinungsbild Ihres Startseite",
    "subtitle": "Fügen Sie Ihr Logo, Markenfarben und Bilder hinzu",
    "customizeTheme": "THEMA BESONDERS ANFERTIGEN",
    "totalSales": "Gesamtumsatz",
    "sales": "Verkauf",
    "salesPerformance": "Umsatzentwicklung",
    "lastDay": "Letzter Tag",
    "lastWeek": "Letzte Woche",
    "lastMonth": "Letzte Monat",
    "lastQuarter": "Letzte Quartal",
    "lastYear": "Letzte Jahr",
    "ordersBreakdown": "Aufschlüsselung der Bestellungen",
    "lastOrders": "Letzte Bestellungen",
    "fulfilledOrders": "Erfüllte Bestellungen",
    "widget": {
      "extension": "Widget-Erweiterbarkeit",
      "visibility": "Widget-Sichtbarkeit",
      "show": "Widget anzeigen",
      "columns": "Widget-Spalten",
      "limit": "Widget-Limit"
    }
  },
  "pendingOrders": "Ausstehende Bestellungen",
  "totalOrders": "Bestellungen insgesamt",
  "order": "Befehl",
  "orderDate": "Auftragsdatum",
  "customer": "Kunde",
  "company": "Gesellschaft",
  "orderAmount": "Bestellbetrag",
  "deliveryDate": "Liefertermin",
  "deliveryTime": "Lieferzeit",
  "status": "Status",
  "itemsPerPage": "Objekte pro Seite",
  "manage": "Verwaltung",
  "tenant": "Mieter",
  "site": "Seite",
  "currency": "Währung",
  "label": "Etikette",
  "manageSites": "Websites verwalten",
  "manageLanguages": "Sprachen verwalten",
  "manageCurrencies": "Währungen verwalten",
  "commerceStudio": "Handelsstudio",
  "commerceExec": "Handelsausführung",
  "companySettings": "Firmeneinstellungen",
  "accountSettings": "Kontoeinstellungen",
  "management": {
    "commerceManagement": "Handelsverwaltung",
    "compose": "Stellen Sie Ihre digitale Handelslösung zusammen",
    "add": "Hinzufügen oder Entfernen von Commerce-Management-Apps (gebündelte Geschäftsfunktionen)",
    "apps": "APPS VERWALTEN"
  },
  "settings": {
    "appSettings": "Apps-Einstellungen",
    "manageApps": "Verwalten Sie E-Commerce-Apps als Teil Ihres Composable Commerce",
    "theme": "Themen Einstellungen",
    "personalize": "Personalisieren Sie das Erscheinungsbild Ihres Management-Dashboards",
    "changePass": "Passwort ändern",
    "changeYourPass": "Ändern Sie Ihr Passwort",
    "logout": "Abmelden vom Management-Dashboard"
  },
  "pdfBtn": "PDF-Schaltfläche",
  "viewBtn": "Ansicht-Schaltfläche",
  "addUnit": "Einheit hinzufügen",
  "editUnit": "Einheit bearbeiten",
  "unitsManagement": "Einheitenverwaltung",
  "addCoupon": "Gutschein hinzufügen",
  "editCoupon": "Gutschein bearbeiten",
  "couponsManagement": "Gutscheinverwaltung",
  "editOrder": "Bestellung bearbeiten",
  "ordersManagement": "Auftragsverwaltung",
  "picklistsManagement": "Auswahllistenverwaltung",
  "sepaExp": "SEPA-Export",
  "sepaExpManagement": "SEPA-Exportmanagement",
  "editCustomer": "Kunde bearbeiten",
  "customersManegement": "Kundenverwaltung",
  "addSupplier": "Lieferant hinzufügen",
  "editSupplier": "Lieferant bearbeiten",
  "suppliersManagement": "Lieferantenverwaltung",
  "addLabel": "Etikett hinzufügen",
  "editLabel": "Label bearbeiten",
  "labelsManagement": "Etikettenverwaltung",
  "addBrand": "Marke hinzufügen",
  "editBrand": "Marke bearbeiten",
  "brandsManagement": "Markenverwaltung",
  "addSite": "Website hinzufügen",
  "editSie": "Website bearbeiten",
  "sitesManagement": "Standortverwaltung",
  "addCountry": "Land hinzufügen",
  "editCountry": "Land bearbeiten",
  "countriesManagement": "Länderverwaltung",
  "addLang": "Sprache hinzufügen",
  "editLang": "Sprache bearbeiten",
  "langManagement": "Sprachverwaltung",
  "addCurrency": "Währung hinzufügen",
  "editCurrency": "Währung bearbeiten",
  "currencyManagement": "Währungsmanagement",
  "addLocation": "Ort hinzufügen",
  "editLocation": "Standort ändern",
  "locationsManagement": "Standortverwaltung",
  "addFee": "Gebühr hinzufügen",
  "editFee": "Bearbeitungsgebühr",
  "feesManagement": "Liefergebührenverwaltung",
  "addZone": "Lieferzone hinzufügen",
  "editZone": "Lieferzone bearbeiten",
  "zonesManagement": "Lieferzonenverwaltung",
  "addDeliv": "Liefermethode hinzufügen",
  "editDeliv": "Liefermethode bearbeiten",
  "delivManagement": "Liefermethodenverwaltung",
  "addDelivGrp": "Bereitstellungsgruppe hinzufügen",
  "editDelivGrp": "Bereitstellungsgruppe bearbeiten",
  "delivGrpManagement": "Bereitstellungsgruppenverwaltung",
  "addTax": "Steuer hinzufügen",
  "editTax": "Steuer bearbeiten",
  "taxesManagement": "Steuerverwaltung",
  "addDelivArea": "Liefergebiet hinzufügen",
  "editDelivArea": "Liefergebiet bearbeiten",
  "delivAreaManagement": "Liefergebietsverwaltung",
  "addDelivTime": "Lieferzeit hinzufügen",
  "editDelivTime": "Lieferzeit bearbeiten",
  "delivTimeManagement": "Lieferzeitverwaltung",
  "addCatalogue": "Katalog hinzufügen",
  "editCtalogue": "Katalog bearbeiten",
  "cataloguesManagement": "Katalogverwaltung",
  "addCat": "Kategorie hinzufügen",
  "editCat": "Kategorie bearbeiten",
  "catManagement": "Kategorienverwaltung",
  "addProd": "Produkt hinzufügen",
  "editProd": "Produkt bearbeiten",
  "prodManagement": "Produktverwaltung",
  "couponReports": "Gutscheinberichte",
  "revenueReports": "Umsatzberichte",
  "extension": "Verlängerung",
  "addApp": "App hinzufügen",
  "addGrp": "Gruppe hinzufügen",
  "editApp": "App bearbeiten",
  "addAppsGrp": "Apps-Gruppe hinzufügen",
  "editAppsGrp": "Apps-Gruppe bearbeiten",
  "newSepa": "Neuer SEPA-Export",
  "new": "NEU",
  "extens": "Erweiterbarkeit",
  "columns": "Säulen",
  "limit": "Grenze",
  "printList": "Packlisten drucken",
  "code": "Code",
  "name": "Name",
  "art": "Art",
  "select": "AUSWÄHLEN",
  "view": "ANSICHT",
  "edit": "BEARBEITEN",
  "deleteUp": "LÖSCHEN",
  "noOrders": "Sie haben noch keine Bestellungen",
  "noPicklists": "Sie haben noch keine Auswahllisten definiert",
  "noSepa": "Sie haben keine SEPA-Exporte",
  "startSepaExp": "SEPA-EXPORT STARTEN",
  "noCatalogues": "Sie haben noch keine Kataloge definiert",
  "addFirstCatalogue": "FÜGEN SIE IHREN ERSTEN KATALOG HINZU",
  "noCat": "Sie haben noch keine Kategorien definiert",
  "addFirstCat": "FÜGEN SIE IHRE ERSTE KATEGORIE HINZU",
  "allCatalogues": "Alle Kataloge",
  "noProd": "Sie haben noch keine Produkte definiert",
  "addFirstProd": "FÜGEN SIE IHR ERSTES PRODUKT HINZU",
  "noLabels": "Sie haben noch keine Labels definiert",
  "addFirstLabel": "FÜGEN SIE IHR ERSTES ETIKETT HINZU",
  "noBrands": "Sie haben noch keine Marken definiert",
  "addFirstBrand": "FÜGEN SIE IHRE ERSTE MARKE HINZU",
  "noSuppliers": "Sie haben noch keine Lieferanten definiert",
  "addFirstSupplier": "FÜGEN SIE IHREN ERSTEN LIEFERANTEN HINZU",
  "noLocations": "Sie haben noch keine Standorte definiert",
  "addFirstLocation": "FÜGEN SIE IHREN ERSTEN STANDORT HINZU",
  "noCustomers": "Sie haben noch keine Kunden",
  "noCoupons": "Sie haben noch keine Gutscheine definiert",
  "addFirstCoupon": "FÜGEN SIE IHREN ERSTEN GUTSCHEIN HINZU",
  "customerRev": "Kundeneinnahmen",
  "supplierRev": "Lieferanteneinnahmen",
  "articleRev": "Artikel Einnahmen",
  "customerRevBy": "Kundenumsätze nach",
  "Month": "Monat",
  "Week": "Woche",
  "From": "Von",
  "to": "zu",
  "Generate": "Generieren",
  "generating": "Erstellen...",
  "noDelivZone": "Sie haben noch keine Lieferzonen definiert",
  "addFirstDelivZone": "FÜGEN SIE IHRE ERSTE LIEFERZONE HINZU",
  "addZoneFirst": "Sie müssen zuerst eine Lieferzone hinzufügen, um Liefermethoden zu definieren",
  "noDelivMethod": "Sie haben noch keine Liefermethoden definiert",
  "addFirstDelivMethod": "FÜGEN SIE IHRE ERSTE LIEFERMETHODE HINZU",
  "noDelivGroups": "Sie haben noch keine Gruppen definiert",
  "addFirstDelivGroup": "FÜGEN SIE IHRE ERSTE LIEFERGRUPPE HINZU",
  "noDelivArea": "Sie haben noch keine Liefergebiete definiert",
  "addFirstDelivArea": "FÜGEN SIE IHR ERSTES LIEFERGEBIET HINZU",
  "noDelivTime": "Sie haben noch keine Lieferzeiten definiert",
  "addFirstDelivTime": "FÜGEN SIE IHRE ERSTE LIEFERZEIT HINZU",
  "noFees": "Sie haben noch keine Gebühren definiert",
  "addFirstFee": "FÜGEN SIE IHRE ERSTE GEBÜHR HINZU",
  "noUnits": "Sie haben noch keine Einheiten definiert",
  "addFirstUnit": "FÜGEN SIE IHRE ERSTE EINHEIT HINZU",
  "noTaxes": "Sie haben noch keine Steuern definiert",
  "addFirstTax": "FÜGEN SIE IHRE ERSTE STEUER HINZU",
  "noSites": "Sie haben noch keine Sites definiert",
  "addFirstSite": "FÜGEN SIE IHRE ERSTE WEBSITE HINZU",
  "noCountries": "Sie haben noch keine Länder definiert",
  "addFirstCountry": "FÜGEN SIE IHR ERSTES LAND HINZU",
  "noCurrencies": "Sie haben noch keine Währungen definiert",
  "addFirstCurrency": "FÜGEN SIE IHRE ERSTE WÄHRUNG HINZU",
  "noLang": "Sie haben noch keine Sprachen definiert",
  "addFirstLang": "FÜGEN SIE IHRE ERSTE SPRACHE HINZU",
  "colorTheme": "Farbschema",
  "primaryColor": "Primärfarbe",
  "secondaryColor": "Sekundäre Farbe",
  "defaultColor": "Standardfarbe",
  "logoLight": "Logo-Licht",
  "logoDark": "Logo dunkel",
  "saveTheme": "Designeinstellungen speichern",
  "reset": "Zurücksetzen",
  "resetAll": "Alles zurücksetzen",
  "ok": "Annehmen",
  "confirm": "Bestätigen Sie",
  "resetDefaultConf": "Möchten Sie wirklich auf die Standardeinstellungen zurücksetzen?",
  "catalogue": "Katalog",
  "category": "Kategorie",
  "searchProd": "Produktsuche",
  "searchByArt": "Suche nach Artikelcode",
  "categories": {
    "list": {
      "columns": {
        "code": "Code",
        "name": "Kategorie Name",
        "published": "Veröffentlicht",
        "select": "Wählen Sie",
        "visibilityStart": "Sichtbarkeit Beginn",
        "visibilityEnd": "Sichtbarkeit Ende"
      }
    }
  },
  "catalogs": {
    "list": {
      "columns": {
        "name": "Name",
        "publishedSites": "Published to",
        "status": "Status",
        "visibilityStart": "Sichtbarkeit Beginn",
        "visibilityEnd": "Sichtbarkeit Ende"
      }
    }
  }
};
