import { env } from '../../utils'

/**
 * Validation on the reponse
 * @param {Object} response - Response JSON object
 * @return {Promise} resolve
 */
const handleResolve = (response, resolve) => {
  // console.log('resolve', response)
  // check if there is a problem with the token -> redirect to login page
  if (
    response &&
    response.fault &&
    response.fault.faultstring &&
    response.fault.faultstring.toLowerCase().indexOf('token') > -1
  )
    document.location = env.logout_url
  if (
    response &&
    response.fault &&
    response.fault.details &&
    response.fault.details.errorcode &&
    response.fault.details.errorcode.toLowerCase().indexOf('token') > -1
  )
    document.location = env.logout_url
  resolve(response)
}

/**
 * Validation on the reject error
 * @param {Object} error - Error JSON object
 * @return {Promise} reject
 */
const handleReject = (error, reject) => {
  // console.log('reject', error)
  // check if there is a problem with the token -> redirect to login page
  if (
    error &&
    error.fault &&
    error.fault.faultstring &&
    error.fault.faultstring.toLowerCase().indexOf('token') > -1
  )
    document.location = env.logout_url
  if (
    error &&
    error.fault &&
    error.fault.details &&
    error.fault.details.errorcode &&
    error.fault.details.errorcode.toLowerCase().indexOf('token') > -1
  )
    document.location = env.logout_url
  reject(error)
}

export { handleResolve, handleReject }
