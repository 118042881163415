import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Units
/**
 * Get units
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getUnits = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      pageNumber: 1,
      pageSize: 1000,
      // sort: 'code:asc',
    }
  fetch(`${env.api_url}/unit-handling/${auth.tenant}/units?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get unit based on id
 * @param {String} id - Unit id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getUnit = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/unit-handling/${auth.tenant}/units/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add unit
 * @param {Object} unit - Unit object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addUnit = (unit, auth, resolve, reject) => {
  fetch(`${env.api_url}/unit-handling/${auth.tenant}/units`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(unit),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update unit based on id
 * @param {String} id - Unit id
 * @param {Object} unit - Unit object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateUnit = (id, unit, auth, resolve, reject) => {
  fetch(`${env.api_url}/unit-handling/${auth.tenant}/units/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(unit),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete unit based on id
 * @param {String} id - Unit id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteUnit = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/unit-handling/${auth.tenant}/units/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // units
  getUnits,
  getUnit,
  addUnit,
  updateUnit,
  deleteUnit,
}
