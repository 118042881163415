import React from 'react'
import { CSpinner } from '@coreui/react-pro'

const Loader = (props) => {
  const { type } = props
  if (type === 'basic') return <CSpinner color="light" />
  if (type === 'block') return <CSpinner color="light" variant="grow" className="d-block" />
  return <CSpinner color="light" variant="grow" className="mt-3" />
}

export default Loader
