import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Products
/**
 * Get products
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
/*
const getProductsExpanded = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      pageSize: 100,
      // q: 'id:(1,2,3)'
    }
  fetch(`${env.api_url}/pcm/products?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}
*/

/**
 * Get products
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getProducts = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      pageSize: 100,
      // sort: 'name:asc',
      // fields: 'id,code,name,prices,media',
    }
  fetch(`${env.api_url}/product/${auth.tenant}/products?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': auth.language,
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get product based on id
 * @param {String} id - Product id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getProduct = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/product/${auth.tenant}/products/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add product
 * @param {Object} product - Product object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addProduct = (product, auth, resolve, reject) => {
  fetch(`${env.api_url}/product/${auth.tenant}/products`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(product),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add product and assign it to a category
 * @param {String} categoryId - Category ID
 * @param {Object} product - Product object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
/*
const addProductCategory = (categoryId, product, auth, resolve, reject) => {
  fetch(`${env.api_url}/pcm/products?categoryId=${categoryId}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(product),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}
*/

/**
 * Add/upload product media (image)
 * @param {Object} file - File
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addProductMedia = (file, id, auth, resolve, reject) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('metadata', JSON.stringify({ domain: 'product' }))
  fetch(`${env.api_url}/product/${auth.tenant}/products/${id}/media`, {
    headers: {
      // 'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: formData,
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add product media (image) to product
 * @param {Object} media - Image/media object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addProductMedia2 = (media, id, auth, resolve, reject) => {
  fetch(`${env.api_url}/product/${auth.tenant}/products/${id}/media2`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(media),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update product based on id
 * @param {String} id - Product id
 * @param {Object} product - Product object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateProduct = (id, product, auth, resolve, reject) => {
  fetch(`${env.api_url}/product/${auth.tenant}/products/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(product),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete product based on id
 * @param {String} id - Product id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteProduct = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/product/${auth.tenant}/products/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete product image
 * @param {String} mediaId - Media id
 * @param {String} id - Product id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteProductMedia = (mediaId, id, auth, resolve, reject) => {
  fetch(`${env.api_url}/product/${auth.tenant}/products/${id}/media/${mediaId}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // products
  // getProductsExpanded,
  getProducts,
  getProduct,
  addProduct,
  // addProductCategory,
  addProductMedia,
  addProductMedia2,
  updateProduct,
  deleteProduct,
  deleteProductMedia,
}
