import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Locations
/**
 * Get locations
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getLocations = (auth, resolve, reject) => {
  fetch(`${env.api_url}/availability/${auth.tenant}/locations/${auth.site}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update location based on id
 * @param {Array} location - Locations array of objects
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateLocations = (locations, auth, resolve, reject) => {
  fetch(`${env.api_url}/availability/${auth.tenant}/locations/${auth.site}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(locations),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete location based on id
 * @param {String} id - Location id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteLocation = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/availability/${auth.tenant}/locations/${auth.site}/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // locations
  getLocations,
  updateLocations,
  deleteLocation,
}
