import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Coupons
/**
 * Get coupons
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getCoupons = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      pageNumber: 1,
      pageSize: 1000,
      sort: 'code:asc',
    }
  fetch(`${env.api_url}/coupon-v2/${auth.tenant}/coupons?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get coupon based on id
 * @param {String} id - Coupon id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getCoupon = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/coupon-v2/${auth.tenant}/coupons/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add coupon
 * @param {Object} unit - Coupon object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCoupon = (coupon, auth, resolve, reject) => {
  fetch(`${env.api_url}/coupon-v2/${auth.tenant}/coupons`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(coupon),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update coupon based on id
 * @param {String} id - Coupon id
 * @param {Object} unit - Coupon object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateCoupon = (id, coupon, auth, resolve, reject) => {
  fetch(`${env.api_url}/coupon-v2/${auth.tenant}/coupons/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(coupon),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete coupon based on id
 * @param {String} id - Coupon id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteCoupon = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/coupon-v2/${auth.tenant}/coupons/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // coupons
  getCoupons,
  getCoupon,
  addCoupon,
  updateCoupon,
  deleteCoupon,
}
