import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Jobs
/**
 * Get jobs
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getJobs = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      siteCode: auth.site,
      pageNumber: 1,
      pageSize: 1000,
      sort: 'createdAt:desc',
    }
  fetch(`${env.api_url}/sepa-export/${auth.tenant}/jobs?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get job based on id
 * @param {String} id - Job id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getJob = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/sepa-export/${auth.tenant}/jobs/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add job
 * @param {Object} job - Job object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addJob = (job, auth, resolve, reject) => {
  fetch(`${env.api_url}/sepa-export/${auth.tenant}/jobs`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(job),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update job based on id
 * @param {String} id - Job id
 * @param {Object} job - Job object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateJob = (id, job, auth, resolve, reject) => {
  fetch(`${env.api_url}/sepa-export/${auth.tenant}/jobs/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(job),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete job based on id
 * @param {String} id - Job id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteJob = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/sepa-export/${auth.tenant}/jobs/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get job file based on file id
 * @param {String} id - File id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getJobFile = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/sepa-export/${auth.tenant}/files/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.text())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // jobs
  getJobs,
  getJob,
  addJob,
  updateJob,
  deleteJob,
  getJobFile,
}
