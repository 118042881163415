import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Configurations
/**
 * Get configurations
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getConfigurations = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      // keys: '',
    }
  fetch(
    `${env.api_url}/configuration/${auth.tenant}/configurations?${new URLSearchParams(options)}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': '*',
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get configuration based on id
 * @param {String} id - Configuration id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getConfiguration = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add configuration
 * @param {Object} configuration - Configuration object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addConfiguration = (configuration, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(configuration),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update configuration based on id
 * @param {String} id - Configuration id
 * @param {Object} configuration - Configuration object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateConfiguration = (id, configuration, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(configuration),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete configuration based on id
 * @param {String} id - Configuration id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteConfiguration = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add theme media (image)
 * @param {Object} file - File
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addThemeMedia = (file, auth, resolve, reject) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('metadata', JSON.stringify({ domain: 'theme' }))
  fetch(`${env.api_url}/brand/media`, {
    headers: {
      // 'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Language': '*',
      // 'Accept-Language': '*',
    },
    method: 'POST',
    body: formData,
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // configurations
  getConfigurations,
  getConfiguration,
  addConfiguration,
  updateConfiguration,
  deleteConfiguration,
  // theme
  addThemeMedia,
}
