import React, { useEffect } from 'react'
const { createContext, useContext, useState } = require('react')

const AppContext = createContext({})

export const useAppContext = () => useContext(AppContext)

export const AppContextProvider = ({ children }) => {
  const [state, setState] = useState({
    sidebarShow: true,
    asideShow: true,
    sidebarUnfoldable: false,
    subheaderShow: false,
    aside: { app: 'dashboard', title: 'Dashboard' },
  })

  useEffect(() => {
    console.log(state)
  }, [state])
  const toggleSidebarShow = (value) => {
    console.log('toggleSidebarShow')
    setState((prev) => ({ ...prev, sidebarShow: value !== undefined ? value : !prev.sidebarShow }))
  }
  const toggleAsideShow = (value) => {
    console.log('toggleAsideShow')
    setState((prev) => ({ ...prev, asideShow: value !== undefined ? value : !prev.asideShow }))
  }
  const setAside = (newAside) => {
    console.log('setAside', newAside)
    setState((prev) => ({
      ...prev,
      aside: newAside,
    }))
  }

  const toggleSidebarUnfoldable = (isUnfoldable) => {
    console.log('toggleSidebarUnfoldable', isUnfoldable)
    setState((prev) => {
      console.log(prev)
      return {
        ...prev,
        sidebarUnfoldable: isUnfoldable !== undefined ? isUnfoldable : !prev.sidebarUnfoldable,
      }
    })
  }

  const toggleSubHeaderShow = (show) => {
    console.log('toggleSubHeaderShow')
    setState((prev) => ({
      ...prev,
      subheaderShow: show !== undefined ? show : !prev.subheaderShow,
    }))
  }
  return (
    <AppContext.Provider
      value={{
        ...state,
        toggleSidebarShow,
        toggleAsideShow,
        setAside,
        toggleSidebarUnfoldable,
        toggleSubHeaderShow,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
