import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Languages
/**
 * Get languages
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getLanguages = (auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_lang`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get language based on id
 * @param {String} id - Language id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getLanguage = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_lang`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add language
 * @param {Object} language - Language object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addLanguage = (language, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_lang`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(language),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update language based on id
 * @param {String} id - Language id
 * @param {Object} language - Language object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateLanguage = (id, language, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_lang`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(language),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete language based on id
 * @param {String} id - Language id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteLanguage = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_lang`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // languages
  getLanguages,
  getLanguage,
  addLanguage,
  updateLanguage,
  deleteLanguage,
}
