import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Picklists
/**
 * Get picklists
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getPicklists = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      siteCode: auth.site,
      q: 'type:picklist', // +metadata.siteCode:${auth.site}
      pageNumber: 1,
      pageSize: 1000,
      sort: 'metadata.creationDate:desc',
    }
  fetch(
    `${env.api_url}/html2pdf/${auth.tenant}/html2pdf/mediaObject?${new URLSearchParams(options)}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': '*',
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get picklist based on id
 * @param {String} id - Picklist id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getPicklist = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/html2pdf/${auth.tenant}/html2pdf/mediaObject/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add picklist
 * @param {Object} picklist - Picklist object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addPicklist = (picklist, auth, resolve, reject) => {
  fetch(`${env.api_url}/html2pdf/${auth.tenant}/html2pdf/mediaObject`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(picklist),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update picklist based on id
 * @param {String} id - Picklist id
 * @param {Object} picklist - Picklist object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updatePicklist = (id, picklist, auth, resolve, reject) => {
  fetch(`${env.api_url}/html2pdf/${auth.tenant}/html2pdf/mediaObject/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(picklist),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete picklist based on id
 * @param {String} id - Picklist id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deletePicklist = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/html2pdf/${auth.tenant}/html2pdf/mediaObject/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get PDF for a pick list link
 * @param {String} link - PDF link
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getPdfPickList = (link, auth, resolve, reject) => {
  fetch(link, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // picklists
  getPicklists,
  getPicklist,
  addPicklist,
  updatePicklist,
  deletePicklist,
  getPdfPickList,
}
