import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Currencies
/**
 * Get currencies
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getCurrencies = (auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_curr`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get currency based on id
 * @param {String} id - Currency id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getCurrency = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_curr`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add currency
 * @param {Object} currency - Currency object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCurrency = (currency, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_curr`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(currency),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update currency based on id
 * @param {String} id - Currency id
 * @param {Object} currency - Currency object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateCurrency = (id, currency, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_curr`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(currency),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete currency based on id
 * @param {String} id - Currency id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteCurrency = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/configuration/${auth.tenant}/configurations/project_curr`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // currencies
  getCurrencies,
  getCurrency,
  addCurrency,
  updateCurrency,
  deleteCurrency,
}
