import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Prices
/**
 * Get prices
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getPrices = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      pageNumber: 1,
      pageSize: 1000,
      // sort: 'code:asc',
    }
  fetch(`${env.api_url}/price/${auth.tenant}/prices?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get price based on id
 * @param {String} id - Price id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getPrice = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/price/${auth.tenant}/prices/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add price
 * @param {Object} price - Price object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addPrice = (price, auth, resolve, reject) => {
  fetch(`${env.api_url}/price/${auth.tenant}/prices`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(price),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update price based on id
 * @param {String} id - Price id
 * @param {Object} price - Price object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updatePrice = (id, price, auth, resolve, reject) => {
  fetch(`${env.api_url}/price/${auth.tenant}/prices/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(price),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete price based on id
 * @param {String} id - Price id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deletePrice = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/price/${auth.tenant}/prices/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // prices
  getPrices,
  getPrice,
  addPrice,
  updatePrice,
  deletePrice,
}
