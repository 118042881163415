import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Customers
/**
 * Get customers
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getCustomers = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      pageNumber: 1,
      pageSize: 500,
      sort: 'firstName:asc',
    }
  fetch(`${env.api_url}/caas-customer/${auth.tenant}/customers?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get customer based on id
 * @param {String} id - Customer id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getCustomer = (id, auth, resolve, reject, options) => {
  if (!options)
    options = {
      expand: 'addresses,mixin:*',
    }
  fetch(
    `${env.api_url}/caas-customer/${auth.tenant}/customers/${id}?${new URLSearchParams(options)}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': '*',
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add customer
 * @param {Object} unit - Customer object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCustomer = (customer, auth, resolve, reject) => {
  fetch(`${env.api_url}/caas-customer/${auth.tenant}/customers`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
      //'session-id': auth.access_token,
    },
    method: 'POST',
    body: JSON.stringify(customer),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add customer address
 * @param {String} customerId - Customer id
 * @param {Object} address - Address Object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCustomerAddress = (customerId, address, auth, resolve, reject) => {
  fetch(`${env.api_url}/caas-customer/${auth.tenant}/customers/${customerId}/addresses`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(address),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update customer based on id
 * @param {String} id - Customer id
 * @param {Object} unit - Customer object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateCustomer = (id, customer, auth, resolve, reject) => {
  fetch(`${env.api_url}/caas-customer/${auth.tenant}/customers/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PATCH',
    body: JSON.stringify(customer),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update customer address based on customer and address id
 * @param {String} customerId - Customer id
 * @param {String} id - Address id
 * @param {Object} address - Address Object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateCustomerAddress = (customerId, id, address, auth, resolve, reject) => {
  fetch(`${env.api_url}/caas-customer/${auth.tenant}/customers/${customerId}/addresses/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PATCH',
    body: JSON.stringify(address),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete customer based on id
 * @param {String} id - Customer id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteCustomer = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/caas-customer/${auth.tenant}/customers/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // customers
  getCustomers,
  getCustomer,
  addCustomer,
  addCustomerAddress,
  updateCustomer,
  updateCustomerAddress,
  deleteCustomer,
}
