import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Reports
/**
 * Generate report
 * @param {String} type - Report type
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const createReport = (type, auth, resolve, reject, options) => {
  if (!options) options = {} // date=01-10-2021%2000:00:00,31-12-2022%20%2023:59:59
  fetch(`${env.api_url}/report/${type}?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get report based on id
 * @param {String} id - Report id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getReport = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/report/results/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // reports
  createReport,
  getReport,
}
