import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// catalogs
/**
 * Get catalogs
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getCatalogues = (auth, resolve, reject, options) => {
  if (!options) options = {}
  fetch(`${env.api_url}/catalog/${auth.tenant}/catalogs?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get catalog based on id
 * @param {String} id - catalog id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getCatalogue = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/catalog/${auth.tenant}/catalogs/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add catalog
 * @param {Object} catalog - catalog object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCatalogue = (catalog, auth, resolve, reject) => {
  fetch(`${env.api_url}/catalog/${auth.tenant}/catalogs`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'POST',
    body: JSON.stringify(catalog),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update catalog based on id
 * @param {String} id - catalog id
 * @param {Object} catalog - catalog object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateCatalogue = (id, catalog, auth, resolve, reject) => {
  fetch(`${env.api_url}/catalog/${auth.tenant}/catalogs/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
    },
    method: 'PUT',
    body: JSON.stringify(catalog),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete catalog based on id
 * @param {String} id - catalog id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteCatalogue = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/catalog/${auth.tenant}/catalogs/${id}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // catalogs
  getCatalogues,
  getCatalogue,
  addCatalogue,
  updateCatalogue,
  deleteCatalogue,
}
