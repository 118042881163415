import { env } from '../../utils'
import { handleResolve, handleReject } from './Intercept'

// Categories
/**
 * Get categories
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getCategories = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      showUnpublished: true,
      sort: 'name:asc',
      pageSize: 99999999,
    }
  fetch(`${env.api_url}/category/${auth.tenant}/categories?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
      'X-version': 'v2',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get categories tree
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getCategoriesTree = (auth, resolve, reject, options) => {
  if (!options)
    options = {
      unpublished: true,
    }
  fetch(
    `${env.api_url}/category/${auth.tenant}/categories/categoryTree?${new URLSearchParams(
      options,
    )}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': '*',
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get subcategories of category id
 * @param {String} id - Category id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const getSubcategories = (id, auth, resolve, reject) => {
  fetch(`${env.api_url}/category/${auth.tenant}/categories/${id}/subcategories`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get products/assignments in categoryId
 * @param {String} id - Category id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getCategoryProducts = (id, auth, resolve, reject, options) => {
  if (!options)
    options = {
      recursive: true,
    }
  fetch(
    `${env.api_url}/category/${auth.tenant}/categories/${id}/assignments?${new URLSearchParams(
      options,
    )}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
        'Accept-Language': '*',
      },
      method: 'GET',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Get category based on id
 * @param {String} id - Category id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 * @param {Object} options - Object with GET params for filtering, paging, sorting
 */
const getCategory = (id, auth, resolve, reject, options) => {
  if (!options) options = {}
  /*
    options = {
      expand: 'assignments', // subcategories, parent
    }
  */
  fetch(`${env.api_url}/category/${auth.tenant}/categories/${id}?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Accept-Language': '*',
      'X-version': 'v2',
    },
    method: 'GET',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add category
 * @param {Object} category - Category object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCategory = (category, auth, resolve, reject) => {
  fetch(`${env.api_url}/category/${auth.tenant}/categories?publish=${category.published}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
      'X-Version': 'v2',
    },
    method: 'POST',
    body: JSON.stringify(category),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Add category assignment (Ex: assign a product to a category)
 * @param {Object} assignment - Assignment object
 * @param {String} id - Category id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const addCategoryAssignment = (assignment, id, auth, resolve, reject) => {
  fetch(`${env.api_url}/category/${auth.tenant}/categories/${id}/assignments`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
      'X-Version': 'v2',
    },
    method: 'POST',
    body: JSON.stringify(assignment),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete category product assignments
 * @param {String} categoryId - Category ID
 * @param {String} productId - Product ID
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteCategoryAssignments = (categoryId, productId, auth, resolve, reject) => {
  const options = {
    'ref.id': productId,
    'ref.type': 'product',
  }
  fetch(
    `${env.api_url}/category/${
      auth.tenant
    }/categories/${categoryId}/assignments?${new URLSearchParams(options)}`,
    {
      headers: {
        Host: env.host,
        Authorization: `Bearer ${auth.access_token}`,
      },
      method: 'DELETE',
    },
  )
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Update category based on id
 * @param {String} id - Category id
 * @param {Object} unit - Category object
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const updateCategory = (id, category, auth, resolve, reject) => {
  fetch(`${env.api_url}/category/${auth.tenant}/categories/${id}?publish=${category.published}`, {
    headers: {
      'Content-Type': 'application/json',
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Language': '*',
      'Accept-Language': '*',
      'X-Version': 'v2',
    },
    method: 'PUT',
    body: JSON.stringify(category),
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

/**
 * Delete category based on id
 * @param {String} id - Category id
 * @param {Object} auth - Context
 * @param {Promise} resolve - Callback function for success
 * @param {Promise} reject - Callback function for error
 */
const deleteCategory = (id, auth, resolve, reject) => {
  const options = {
    recursive: true,
    // withSubcategories: true,
  }
  fetch(`${env.api_url}/category/${auth.tenant}/categories/${id}?${new URLSearchParams(options)}`, {
    headers: {
      Host: env.host,
      Authorization: `Bearer ${auth.access_token}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then((response) => handleResolve(response, resolve))
    .catch((error) => handleReject(error, reject))
}

export {
  // categories
  getCategories,
  getCategoriesTree,
  getSubcategories,
  getCategory,
  getCategoryProducts,
  addCategory,
  addCategoryAssignment,
  deleteCategoryAssignments,
  updateCategory,
  deleteCategory,
}
