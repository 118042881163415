import { string } from 'prop-types'

export const TENANT_KEY = 'tenant'
export const ID_SORTING_ASCENDING = 'id:ASC'
export const env = {
  // URLs
  api_url: `${process.env.REACT_APP_API}`,
  login_url: `${process.env.REACT_APP_LOGIN}`,
  logout_url: `${process.env.REACT_APP_LOGIN}/dashboard/logout`,
  reset_url: `${process.env.REACT_APP_LOGIN}/reset/mail`,
  admin_url: `${process.env.REACT_APP_ADMIN}`,
  auth_url: `${process.env.REACT_APP_ADMIN}/login.html`,
  host: process.env.REACT_APP_API,
  production: JSON.parse(
    process.env.REACT_APP_PRODUCTION ? process.env.REACT_APP_PRODUCTION : 'false',
  ),

  // extension key names in Configuration service
  extensions: {
    apps: 'ext_apps',
    theme: 'ext_theme',
    dashboard: 'ext_dashboard',
    categories: 'ext_categories',
    countries: 'ext_countries',
    jobs: 'ext_jobs',
    orders: 'ext_orders',
    picklists: 'ext_picklists',
    reports_coupons: 'ext_reports_coupons',
    taxes: 'ext_taxes',
    locations: 'ext_locations',
    sites: 'ext_sites',
    units: 'ext_units',
    languages: 'ext_languages',
    groups: 'ext_groups',
    labels: 'ext_labels',
    times: 'ext_times',
    areas: 'ext_areas',
    fees: 'ext_fees',
    suppliers: 'ext_suppliers',
    catalogues: 'ext_catalogues',
    zones: 'ext_zones',
    products: 'ext_products',
    methods: 'ext_methods',
    currencies: 'ext_currencies',
    customers: 'ext_customers',
  },
}

const r = document.querySelector(':root')
export const setCssVariable = (name, value) => {
  r.style.setProperty(name, value)
}

export const formatSortParam = (fieldName, order) => {
  return `${fieldName && fieldName.includes('[') ? fieldName.split('[')[0] : fieldName}:${
    order > 0 ? 'ASC' : 'DESC'
  }`
}

export const formatDate = (value) => {
  try {
    if (value) return value.toISOString().split('T')[0]
    else return undefined
  } catch (error) {
    return '-'
  }
}

export const getStatusColor = (status) => {
  if (status.toUpperCase() === 'VISIBLE') return 'green'
  else if (status.toUpperCase() === 'PUBLISHED') return 'blue'
  return 'gray'
}

export const DATE_FORMAT = 'yy-mm-dd'

export const getFilterValue = (filters, fieldName) => {
  return filters[fieldName] && filters[fieldName].value ? filters[fieldName].value : undefined
}

export const getFilterDateValue = (filters, fieldName) =>
  formatDate(getFilterValue(filters, fieldName))

export const getDisplayedValue = (localizedValue, language) => {
  if (localizedValue instanceof string) {
    return localizedValue
  }
  const displayedValue = localizedValue ? localizedValue[language] : ''
  if (displayedValue === undefined) {
    const firstKey = Object.keys(localizedValue)[0]
    return `${localizedValue[firstKey]} (${firstKey})`
  }
  return displayedValue
}

export const getCsvValue = (value) => value.replaceAll(',', '')

export const downloadCsvData = (columns, valuesMappedToString = [], fileName) => {
  let csvContent = `data:text/csv;charset=utf-8,${columns.join(',')},\n`
  csvContent += valuesMappedToString.join('\n')
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName)
  document.body.appendChild(link) // Required for FF
  link.click()
}

export const mapCategoriesToOptions = (categories, parentId, language = 'en') => {
  let map = categories.map((cat) => ({
    value: cat.id,
    text: getDisplayedValue(cat.localizedName, language) + `(${cat.id})`,
    selected: parentId ? cat.id === parentId : false,
  }))
  debugger
  return map
}
